<template>
  <b-modal
    id="modal-disable-mfa"
    ref="modal-disable-mfa"
    footer-class="flex-nowrap"
    no-close-on-backdrop
    centered
  >
    <template #modal-header>
      <h3>{{ $t('modals.twoFactorDisabled.title') }}</h3>
    </template>

    <template #default>
      <div v-if="!successDisabled && !showErrorMsg">
        <div class="success-icon text-center px-2">
          <b-icon icon="exclamation-triangle" variant="info" class="mt-5 mb-4"></b-icon>
          <p class="mt-2 mb-5">{{ $t('modals.twoFactorDisabled.desc') }}</p>
        </div>
      </div>
      <div v-else-if="showErrorMsg" class="success-icon text-center px-2">
        <b-icon icon="exclamation-triangle" variant="danger" class="mt-5 mb-4"></b-icon>
        <p class="mt-2 mb-5">{{ $t('modals.twoFactorDisabled.errorMsg') }}</p>
      </div>
      <div v-else class="success-icon text-center px-2">
        <b-icon icon="check-circle" variant="success" class="mt-5 mb-4"></b-icon>
        <p class="mt-2 mb-5">{{ $t('modals.twoFactorDisabled.successMsg') }}</p>
      </div>
    </template>
    <template #modal-footer>
      <div v-if="!successDisabled && !showErrorMsg">
        <b-button
          class="p-3 border-right-light-grey"
          block
          @click="$emit('cancel-two-factor')"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-button
          variant="primary"
          class="p-3"
          block
          @click="disableTwoFactor"
        >
          {{ $t('modals.twoFactorDisabled.disableButton') }}
        </b-button>
      </div>
      <div v-else-if="showErrorMsg">
        <b-button
          class="p-3 border-right-light-grey"
          block
          @click="$emit('cancel-two-factor')"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
      </div>
      <div v-else>
        <b-button
          class="p-3 border-right-light-grey"
          block
          @click="$emit('disable-two-factor')"
        >
          {{ $t('buttons.ok') }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import SetLoadingIndicator from '@/graphQlQueries/mutations/setLoadingIndicatorInCache';
import Auth from '@aws-amplify/auth';

export default {
  name: 'DisableTwoFactorAuth',
  props: {
    authUser: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      successDisabled: false,
      showErrorMsg: false,
    };
  },
  async created() {
    console.log('DisableTwoFactorAuth created');
  },
  mounted() {
    this.$bvModal.show('modal-disable-mfa');
  },
  methods: {
    disableTwoFactor() {
      this.setLoadingIndicator(true);
      Auth.setPreferredMFA(this.authUser, 'NOMFA')
        .then(() => {
          // show the success message
          this.successDisabled = true;
          this.setLoadingIndicator(false);
          // disable loading
        }).catch((err) => {
          console.error('EnableTwoFactorAuth setPreferredMFA error', err);
          this.showErrorMsg = true;
          this.setLoadingIndicator(false);
        });
    },
    setLoadingIndicator(isLoading) {
      this.$apollo.mutate({
        mutation: SetLoadingIndicator,
        variables: {
          isIndicatorLoading: isLoading,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
/deep/ .disabled {
  cursor: not-allowed;
}
/deep/ .modal-footer > div {
  display: flex;
  width: 100%;
  margin: 0;
}
.success-icon svg {
  font-size: 60px;
}
</style>
