var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-modal", {
    ref: "modal-disable-mfa",
    attrs: {
      id: "modal-disable-mfa",
      "footer-class": "flex-nowrap",
      "no-close-on-backdrop": "",
      centered: ""
    },
    scopedSlots: _vm._u([
      {
        key: "modal-header",
        fn: function() {
          return [
            _c("h3", [_vm._v(_vm._s(_vm.$t("modals.twoFactorDisabled.title")))])
          ]
        },
        proxy: true
      },
      {
        key: "default",
        fn: function() {
          return [
            !_vm.successDisabled && !_vm.showErrorMsg
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "success-icon text-center px-2" },
                    [
                      _c("b-icon", {
                        staticClass: "mt-5 mb-4",
                        attrs: { icon: "exclamation-triangle", variant: "info" }
                      }),
                      _c("p", { staticClass: "mt-2 mb-5" }, [
                        _vm._v(_vm._s(_vm.$t("modals.twoFactorDisabled.desc")))
                      ])
                    ],
                    1
                  )
                ])
              : _vm.showErrorMsg
              ? _c(
                  "div",
                  { staticClass: "success-icon text-center px-2" },
                  [
                    _c("b-icon", {
                      staticClass: "mt-5 mb-4",
                      attrs: { icon: "exclamation-triangle", variant: "danger" }
                    }),
                    _c("p", { staticClass: "mt-2 mb-5" }, [
                      _vm._v(
                        _vm._s(_vm.$t("modals.twoFactorDisabled.errorMsg"))
                      )
                    ])
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "success-icon text-center px-2" },
                  [
                    _c("b-icon", {
                      staticClass: "mt-5 mb-4",
                      attrs: { icon: "check-circle", variant: "success" }
                    }),
                    _c("p", { staticClass: "mt-2 mb-5" }, [
                      _vm._v(
                        _vm._s(_vm.$t("modals.twoFactorDisabled.successMsg"))
                      )
                    ])
                  ],
                  1
                )
          ]
        },
        proxy: true
      },
      {
        key: "modal-footer",
        fn: function() {
          return [
            !_vm.successDisabled && !_vm.showErrorMsg
              ? _c(
                  "div",
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "p-3 border-right-light-grey",
                        attrs: { block: "" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("cancel-two-factor")
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")]
                    ),
                    _c(
                      "b-button",
                      {
                        staticClass: "p-3",
                        attrs: { variant: "primary", block: "" },
                        on: { click: _vm.disableTwoFactor }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("modals.twoFactorDisabled.disableButton")
                            ) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm.showErrorMsg
              ? _c(
                  "div",
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "p-3 border-right-light-grey",
                        attrs: { block: "" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("cancel-two-factor")
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")]
                    )
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "p-3 border-right-light-grey",
                        attrs: { block: "" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("disable-two-factor")
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("buttons.ok")) + " ")]
                    )
                  ],
                  1
                )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }